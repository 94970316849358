import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { ToastContainer } from 'react-toastify';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { createBrowserHistory } from "history";
import { msalInstance } from '../../Containers/Auth/MsalBrowserProvider';

import '@fortawesome/fontawesome-free';
import '@fortawesome/fontawesome-svg-core';
import '@fortawesome/free-brands-svg-icons';
import '@fortawesome/free-regular-svg-icons';
import '@fortawesome/react-fontawesome';

import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import IAuthProps from '../../Containers/Auth/IAuthProps';
import Menubar from '../Layout/Menubar/Menubar';
import Header from '../Layout/Header/Header';
import Footer from '../Layout/Footer/Footer';
import Logout from '../Pages/Logout';
import NotFound from '../Pages/NotFound';
import { getUserProfile } from '../../Store/Reducers/LocalStoreSlice';
import { selectUserProfile } from '../../Store/Selectors/localStoreSelector';
import { getModuleConfig } from '../../Store/Reducers/ModuleConfigSlice';
import { IUserRole } from '../../Models/Auth/IUser';
import Unauthorised from '../Pages/Unauthorised';
import Home from '../Pages/Home';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import TenancyManagement from '../Pages/TenancyManagement';
import OrganisationManagement from '../Pages/OrganisationManagement';
import UserManagement from '../Pages/UserManagement/UserManagement';
import ProjectManagement from '../Pages/ProjectManagement';
import User from '../Pages/User/User';

const tagManagerArgs = {
  gtmId: 'GTM-XXXXXXX'
}

TagManager.initialize(tagManagerArgs);

enum AuthStateEnum {
  Undefined,
  Authorised,
  Unauthorised
}

const App: React.FC<IAuthProps> = (props) => {
  const dispatch = useDispatch();

  const userProfile = useSelector(selectUserProfile);
  const [ accessToken, setAccessToken ] = useState('');

  useEffect(() => {
    let count = 0;
    const interval = setInterval(async () => {
      const token = await props.getAccessToken();
      console.log('Ticking ===', accessToken && token.length)
      count++;
      if (token || (count === 10)) {
        setAccessToken(token);
        clearInterval(interval);
      }
    }, 1000);
  });

  useEffect(() => {
    const refreshFn = async () => {
      const accessToken = await props.getAccessToken();
      console.log('AccessToken ===', accessToken && accessToken.length)
      
      if (accessToken) {
        dispatch(getUserProfile(accessToken));
      }
      else {
        const pos = window.location.href.search('signout');
        if (pos === -1 && props.login) {
          props.login();
        }
      }
    };
    
    refreshFn();
  }, [accessToken, props, dispatch]);

  useEffect(() => {
    const refreshFn = async () => {
      const accessToken = await props.getAccessToken();

      if (accessToken) {
        if(!Object.keys(userProfile).length) {
          await dispatch(getUserProfile(accessToken));
        }
        
        const orgId: string = process.env.REACT_APP_GA_ORG_ID || '';
        // await dispatch(getModuleConfig(accessToken, orgId));  
      }
    };

    refreshFn();
  }, [userProfile, props, dispatch]);

  const signout = (origin: string) => {
    
    // Remove this line after implementing the template
    msalInstance.logoutRedirect();
    
    localStorage.clear();
    if (origin) {
      const originText = origin ? ('?origin=' + origin) : '';
      window.location.href = (process.env.REACT_APP_GERX_LOGOUT_REDIRECT_NEXT_APP_URL + '/signout' + originText) || '/';
    }
    else {
      window.location.href = (process.env.REACT_APP_GERX_LOGOUT_REDIRECT_URL + '/signout') || '/';
    }
  };
  
  const authRoles = process.env.REACT_APP_ROLES;
  const roles = authRoles?.split(',') || [];

  let authState = AuthStateEnum.Undefined;
  if (Object.keys(userProfile || {}).length) {
    const isAuthorised = userProfile?.roles?.some((role: IUserRole) => roles.includes(role.roleName));
    const history = createBrowserHistory();
    if (!isAuthorised) {
      authState = AuthStateEnum.Unauthorised;
      history.push('/unauthorised');
    }
    else {
      authState = AuthStateEnum.Authorised;
    }
  }

  // let error = null;
  // if (props.error) {
  //   error = <ErrorMessage
  //     message={props.error.message}
  //     debug={props.error.debug} />;
  // }

  if (authState === AuthStateEnum.Unauthorised) {
    return (
      <Router>
        <Header />
        <div className="App">
          <Menubar isAuthorised={false} authButtonMethod={signout} user={props.user} />
        </div>
        <Unauthorised />
        <Footer />
      </Router>
    )
  }
  else {
    return (
      <>
        <Router>
          <Route path="/signout" exact render={(props) => {
            const origin = props.location.search.split('?origin=')[1] || '';

            setTimeout(() => signout(origin), 500);
            
            return <>Please wait while we sign you out from GERX</>
          }} />
        </Router>
        {
          window.location.href.search("/signout") > -1 ? <></> :
          <Router>
            <ToastContainer />
            <Header/>
            <div className="App">
              <Menubar isAuthenticated={props.isAuthenticated} isAuthorised={true} authButtonMethod={signout} user={props.user} />
              
              <div key="head">
                <Switch>
                  <Route path="/logout" component={Logout} />

                  <Route path="/unauthorised" exact render={(props: any) => (
                      <Unauthorised />
                    )
                  } />

                  <Route path="/" exact render={(props2) => (
                      <Home {...props2}
                            isAuthenticated={props.isAuthenticated}
                            user={props.user}
                            getTokens={props.getTokens}
                            getAccessToken={props.getAccessToken}
                            authButtonMethod={props.login} />
                    )
                  } />

                  <Route path="/tenancy" exact render={(props2) => (
                      <TenancyManagement {...props2}
                            isAuthenticated={props.isAuthenticated}
                            user={props.user}
                            getTokens={props.getTokens}
                            getAccessToken={props.getAccessToken}
                            authButtonMethod={props.login} />
                    )
                  } />

                  <Route path="/organisation" exact render={(props2) => (
                      <OrganisationManagement {...props2}
                            isAuthenticated={props.isAuthenticated}
                            user={props.user}
                            getTokens={props.getTokens}
                            getAccessToken={props.getAccessToken}
                            authButtonMethod={props.login} />
                    )
                  } />

                  <Route path="/users" exact render={(props2) => (
                      <UserManagement {...props2}
                            isAuthenticated={props.isAuthenticated}
                            user={props.user}
                            getTokens={props.getTokens}
                            getAccessToken={props.getAccessToken}
                            authButtonMethod={props.login} />
                    )
                  } />

                  <Route path="/projects" exact render={(props2) => (
                      <ProjectManagement {...props2}
                            isAuthenticated={props.isAuthenticated}
                            user={props.user}
                            getTokens={props.getTokens}
                            getAccessToken={props.getAccessToken}
                            authButtonMethod={props.login} />
                    )
                  } />

                  <Route path="/user/:userId?" exact render={(props2) => (
                      <User {...props2}
                            isAuthenticated={props.isAuthenticated}
                            user={props.user}
                            getTokens={props.getTokens}
                            getAccessToken={props.getAccessToken}
                            authButtonMethod={props.login} />
                    )
                  } />

                  <Route default component={NotFound} />

                </Switch>

              </div>
            </div>
            <Footer/>
          </Router>
        }
      </>
    );
  }
};

export default App;

import { IUserOrganisation } from "../../Models/App/AppModels";
import UserOrgApiService from "../../Services/UserOrgApiService";

const userUserOrgApiService = new UserOrgApiService();

export const getAllUserOrganisations = async (accessToken: string) : Promise<IUserOrganisation[]> => {
    return await userUserOrgApiService.getAllUserOrganisations(accessToken);
};

export const getCurrentUserOrganisations = async (accessToken: string, userOrgId: string) : Promise<IUserOrganisation> => {
    return await userUserOrgApiService.getCurrentUserOrganisations(accessToken, userOrgId);
};
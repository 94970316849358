import axios from 'axios';

import { MessageModeEnum, showMessage } from '../Helpers/Validator/validationHelper';
import { IUserProfile } from '../Models/Auth/IUser';
import { getConfig } from './serviceHelper';

export default class ModuleConfigApiService {
    private baseUrl?: string;
    private baseModuleName: string | undefined;

    constructor () {
      this.baseUrl = process.env.REACT_APP_API_BASEURI;
        this.baseModuleName = process.env.REACT_APP_BASE_MODULE_NAME;
    }

    showComposedMessage = (methodName: string, url: string, error: any) => {
        const message = `[${methodName}]: ${error.message} - ${url}`;
        showMessage(message, MessageModeEnum.ERROR);
        console.log(message);
    }
    
    getConfigByOrgId = async (accessToken: string, orgId: string) : Promise<any> => {
        const config = getConfig(accessToken);

        const autoApi = `${this.baseUrl}configurations?module=${this.baseModuleName}` + (orgId && `&orgid=${orgId}`)
        
        try {
          const response = await axios.get(autoApi, config);
    
          return response.data.moduleConfigData;
        }
        catch (ex: any) {
          this.showComposedMessage('getConfigByOrgId', autoApi, ex);
        }
    }

    getUserProfile = async (accessToken: string) : Promise<IUserProfile> => {
        const url = `${this.baseUrl}userprofile`;
        let response: any;
        try {
          response = await axios.get(url, getConfig(accessToken));
        }
        catch (ex: any) {
          //this.showComposedMessage('fetchUserProfile', url, ex);
          console.log(`[getUserProfile]: ${url}`);
        }
        finally {
          return response?.data.user[0];
        }
      };
}
import React from 'react';
import {Link} from 'react-router-dom';
import {Navbar, Nav,NavItem, Button} from 'react-bootstrap';

import MenuBanner from '../../../Assets/Logos/bannerlogo.svg';

import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.scss';
import './Menubar.scss';

interface IMenubar {
  isAuthorised: boolean;
  user: any;
  authButtonMethod: any;
}

const Menubar : React.FC<any> = (props: IMenubar) => {
  const homeUrl = process.env.REACT_APP_HOME_SITE_URL || '/';

  return (
    <div className="gerx-menubar">
      <Navbar.Brand as={Link} to={"/"}><img src={MenuBanner} className="App-logo-nav" alt="logo" /></Navbar.Brand>
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Toggle />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          {!props.isAuthorised ? <></> :
            <Nav>
              <a className="nav-link" href={homeUrl}>Home</a>
              <NavItem>
                <Button onClick={props.authButtonMethod} className="btn-link nav-link signup">Sign Out</Button>
              </NavItem>
            </Nav>
          }
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Menubar;

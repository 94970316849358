import { createSelector } from 'reselect';
import { IModulePageInfo, LocalStoreState } from '../Reducers/LocalStoreSlice';
import { RootState } from '../RootStore';


//#region ***** region LocalStore *****/

export const localStoreSelector = (state: RootState) => state.localStore;

export const selectIsSideMenuExpanded = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.isSideMenuExpanded
);

export const selectUserManagementPageInfo = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.modulePageInfo.user as IModulePageInfo
);

export const selectLoadingState = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.isLoading as boolean
);

//#endregion
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const url = process.env.REACT_APP_HOME_SITE_URL || '';

const Unauthorised : React.FC = () => {
    const history = useHistory();
    
    useEffect(() => {
        if (url) {
            setTimeout(() => window.location.href = url, 3000);
        }
        else {
            history.push('/');
        }
    });

    return (
        <>Unauthorised</>
    );
};

export default Unauthorised;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../RootStore';
import { RootState } from "../RootStore";
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { getAllRoles } from '../Api/UserRoleApi';
import { IRole } from '../../Models/App/AppModels';

export interface IUserRoleState {
  roles: IRole[],
  error: any,
}

const initialState : IUserRoleState = {
  roles: [] as IRole[],
  error: null,
};

const UserRoleSlice = createSlice({
  name: "Role",
  initialState: initialState,
  reducers: {
    getRolesSuccess(state, {payload}: PayloadAction<any>) {
      state.roles = payload;
      state.error = null;
    },
    getRolesFailed(state, {payload}: PayloadAction<IRole[]>) {
      state.roles = [] as IRole[];
      state.error = payload;
    },
  }
});

//KS: Export reducer (Can include other reducers here too)
export const UserRoleReducers = UserRoleSlice.reducer;

// //KS: Export action creators
export const {
  getRolesSuccess, 
  getRolesFailed,
} = UserRoleSlice.actions;

/***** API Calls */
export const getUserRoles = (accessToken: string, pageNumber: number = 0, pageSize: number = 0) : AppThunk => async dispatch => {
  try {
    const result = await getAllRoles(accessToken);
    dispatch(getRolesSuccess(result));
  } catch(err) {
    dispatch(getRolesFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

/* Export reducer store: */
export const role = (state: RootState) => state.userRolesStore as any;
import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../RootStore';
import { RootState } from "../RootStore";
import { IModulePageInfoPayload, ModulesEnum, setModulePageInfo } from './LocalStoreSlice';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { IUser } from '../../Models/App/AppModels';
import { deleteUserById, getAllUsers, getUserById, patchUserById, patchUserRoleById, postUser } from '../Api/UserApi';
import { IUserRole } from '../../Models/Auth/IUser';

export interface IUserState {
  users: IUser[],
  user: IUser,
  error: any,
}

const initialState : IUserState = {
  users: [] as IUser[],
  user: {} as IUser,
  error: null,
};

export interface ICreateUser {
  organisationId: string;
  userOrganisationId: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: Array<any>;
  isActive: boolean;
}

const UserSlice = createSlice({
  name: "User",
  initialState: initialState,
  reducers: {
    getUsersSuccess(state, {payload}: PayloadAction<any>) {
      state.users = payload;
      state.error = null;
    },
    getUsersFailed(state, {payload}: PayloadAction<IUser[]>) {
      state.users = {} as IUser[];
      state.error = payload;
    },
    getUserSuccess(state, {payload}: PayloadAction<any>) {
      state.user = payload;
      state.error = null;
    },
    getUserFailed(state, {payload}: PayloadAction<IUser[]>) {
      state.user = {} as IUser;
      state.error = payload;
    },
    patchUserFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    createUserFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    deleteUserSuccess(state, {payload}: PayloadAction<string>) {
      state.users = state.users.filter(user => user._id !== payload);
      state.error = null;
    },
    deleteUserFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    }
  }
});

//KS: Export reducer (Can include other reducers here too)
export const UserReducers = UserSlice.reducer;

// //KS: Export action creators
export const {
  getUsersSuccess, 
  getUsersFailed,
  getUserSuccess, 
  getUserFailed,
  patchUserFailed,
  createUserFailed,
  deleteUserSuccess,
  deleteUserFailed
} = UserSlice.actions;

/***** API Calls */
export const getUsers = (accessToken: string, pageNumber: number, pageSize: number) : AppThunk => async dispatch => {
  try {
    const result = await getAllUsers(accessToken, pageNumber, pageSize);
    dispatch(getUsersSuccess(result.data));
    if (pageNumber === 1) {
      const recordCount = result.metadata.recordCount;
      const payload: IModulePageInfoPayload = {
        moduleName: ModulesEnum.User,
        modulePageInfo: { pageNumber, pageSize, recordCount }
      }
      dispatch(setModulePageInfo(payload));
    }
  } catch(err: any) {
    dispatch(getUsersFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const getUser = (accessToken: string, userId: string) : AppThunk => async dispatch => {
  try {
    const result = await getUserById(accessToken, userId);
    dispatch(getUserSuccess(result));
  } catch(err: any) {
    dispatch(getUserFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const patchUser = (accessToken: string, user: IUser) : AppThunk => async dispatch => {
  try {
    await patchUserById(accessToken, user);
    showMessage('Sucessfully updated User details', MessageModeEnum.SUCCESS);
  } catch(err: any) {
    dispatch(patchUserFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const patchUserRole = (accessToken: string, user: IUser, userRoles: IUserRole[]) : AppThunk => async dispatch => {
  try {
    await patchUserRoleById(accessToken, user, userRoles);
    showMessage('Sucessfully updated User role details', MessageModeEnum.SUCCESS);
  } catch(err: any) {
    dispatch(patchUserFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const createUser = (accessToken: string, user: ICreateUser) : AppThunk => async dispatch => {
  try {
    const result = await postUser(accessToken, user);
    showMessage('Sucessfully created new user', MessageModeEnum.SUCCESS);
    dispatch(getUserSuccess(result));
  } catch(err: any) {
    dispatch(createUserFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const deleteUser = (accessToken: string, userId: string) : AppThunk => async dispatch => {
  try {
    const result = await deleteUserById(accessToken, userId);
    if(result) {
      showMessage('Sucessfully deleted user', MessageModeEnum.SUCCESS);
      dispatch(deleteUserSuccess(userId));
    }
  } catch(err: any) {
    dispatch(deleteUserFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

/* Export reducer store: */
export const user = (state: RootState) => state.userStore as any;
import TenancyApiService from '../../Services/TenancyService';

const tenancyApiService = new TenancyApiService();

export const getAllTenancies = async (accessToken: string) : Promise<any> => {  
  return await tenancyApiService.getAllTenancies(accessToken);
};

export const getTenancy = async (accessToken: string, tenancyId: string) : Promise<any> => {  
  return await tenancyApiService.getTenancy(accessToken, tenancyId);
};
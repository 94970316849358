import { INameValuePair } from "../Models/App/AppModels";

export const loaderStyle = {
    overlay: (base: any) => ({
        ...base,
        background: 'rgb(170,170,170, 0.7)',
        color: 'white',
        width: '100%',
          '& svg circle': {
            stroke: 'rgba(255, 0, 0, 0.2)'
          },
        zIndex: 1
    })
};

export const spinnerStyle: any = {
  position: 'absolute',
  width: '100%',
  height: '40px',
  textAlign: 'center',
  opacity: '50%',
};

export const waitingText = 'Please wait...';

export enum DetailRecordTypes {
  CONTACTS,
  NOTES,
  CHAT
};

export const states: INameValuePair[] = [
  { name: 'Australian Capital Territory', value: 'ACT' },
  { name: 'New South Wales', value: 'NSW' },
  { name: 'Northern Territory', value: 'NT' },
  { name: 'Queensland', value: 'QLD' },
  { name: 'South Australia', value: 'SA' },
  { name: 'Tasmania', value: 'TAS' },
  { name: 'Victoria', value: 'VIC' },  
  { name: 'Western Australia', value: 'WA' },
]
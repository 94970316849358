import { createSelector } from 'reselect';

import { RootState } from '../RootStore';
import { IUserState } from '../Reducers/UserSlice';
import { IUser } from '../../Models/App/AppModels';

export const userSelector = (state: RootState) => state.userStore;

export const selectUsers = createSelector(
    [userSelector],
    (state: IUserState) => state.users as IUser[]
);

export const selectUser = createSelector(
    [userSelector],
    (state: IUserState) => state.user as IUser
);
import { createSelector } from 'reselect';
import { IUserProfile } from '../../Models/Auth/IUser';
import { LocalStoreState, IModulePageInfo, AuthStatusEnum } from '../Reducers/LocalStoreSlice';
import { RootState } from '../RootStore';

export const localStoreSelector = (state: RootState) => state.localStore;

export const selectAuthStatus = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.authStatus as AuthStatusEnum
);

export const selectUserProfile = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.userProfile as IUserProfile
);

export const selectHomePageInfo = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.modulePageInfo.user as IModulePageInfo
);
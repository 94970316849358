import { createSlice, PayloadAction} from '@reduxjs/toolkit';

import { AppThunk } from '../RootStore';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { IUserOrganisation } from '../../Models/App/AppModels';
import { getAllUserOrganisations, getCurrentUserOrganisations } from '../Api/UserOrgApi';

export interface IUserOrgState {
  userOrganisations: IUserOrganisation[],
  currentUserOrganisation: IUserOrganisation,
  error: any;
}

const initialState: IUserOrgState = {
  userOrganisations: [] as IUserOrganisation[],
  currentUserOrganisation: {} as IUserOrganisation,
  error: null
};

const userOrgSlice = createSlice({
    name: "userOrg",
    initialState,
    reducers: {
        getUserOrganisationsSuccess(state: IUserOrgState, {payload}:  PayloadAction<any>) {
            state.userOrganisations = payload;
            state.error = null;
        },
        getUserOrganisationSuccess(state: IUserOrgState, {payload}:  PayloadAction<any>) {
          state.currentUserOrganisation = payload;
          state.error = null;
        },
        getUserOrganisationsFailed(state: IUserOrgState, action: PayloadAction<any>) {
          state.userOrganisations = [] as IUserOrganisation[];
          state.error = action.payload;
        },
        getUserOrganisationFailed(state: IUserOrgState, action: PayloadAction<any>) {
          state.currentUserOrganisation = {} as IUserOrganisation;
          state.error = null;
        },
    }
});

export const { getUserOrganisationsSuccess, getUserOrganisationsFailed, getUserOrganisationSuccess, getUserOrganisationFailed } = userOrgSlice.actions;

export const userOrgReducers = userOrgSlice.reducer;

export const getUserOrgs = (accessToken: string, successFn?: Function) : AppThunk => async dispatch => {
  try {
    const result = await getAllUserOrganisations(accessToken);
    await dispatch(getUserOrganisationsSuccess(result));
    if (successFn) successFn(result);
  } catch(err) {
    console.log("err ", err);
    dispatch(getUserOrganisationsFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const getUserOrg = (accessToken: string, userOrgId: string) : AppThunk => async dispatch => {
  try {
    const result = await getCurrentUserOrganisations(accessToken, userOrgId);
    await dispatch(getUserOrganisationSuccess(result));
  } catch(err) {
    console.log("err ", err);
    dispatch(getUserOrganisationFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../RootStore';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { ISubUser } from '../../Models/App/AppModels';
import { getConfigByOrgId } from '../Api/ModuleConfigApi';

export interface IModuleConfigData {
  modules: IModule[],
  error: any;
};

export interface IModuleConfigurations {
  modules: IModule[],
  error: any;
}

export interface IModule {
    moduleId: string;
    moduleConfig: IModuleConfig[];
    pages: IModulePage[]
};

export interface IModuleConfig {
    configName: string;
    metaData: IMetaData[]; // to be removed;
};

export interface IModulePage {
    id: string;
    sections: IModuleSection[];
};

export interface IModuleSection {
    id: string;
    description?: string;
    fields: IMetaData[];
};

export interface IMetaData {
    metaId: string;
    metaPropId?: string;
    metaName: string;
    metaControl?: string;
    metaDisplayOrder?: number;
    metaPlaceholder?: string;
    metaValues?: IMetaValue[];
    metaRefId?: string;
};

export interface IMetaValue {
    id: string;
    name: string;
    lookup?: string[];
};

const initialState: IModuleConfigData = {
    modules: [],
    error: null
};

export enum MetaControls {
  Dropdown = 'Dropdown',
  Text = 'Text',
  Textarea = 'Textarea',
  Decimal = 'Decimal',
  Date = 'Date',
  Stepper = 'Stepper',
  Multiselect = 'Multiselect'
};

const moduleConfigDataSlice = createSlice({
  name: "moduleConfigurations",
  initialState,
  reducers: {
    getModuleConfigDataSuccess: (state: IModuleConfigData, action: PayloadAction<any>) => { 
      state.modules = action.payload.modules;
      state.error = null;
    },
    getModuleConfigDataFailed: (state: IModuleConfigData, action: PayloadAction<any>) => { 
      state.modules = [];
      state.error = action.payload;
    }
  }
});

export const { getModuleConfigDataSuccess, getModuleConfigDataFailed } = moduleConfigDataSlice.actions;

export const getModuleConfig = (accessToken: string, orgId: string): AppThunk => async dispatch => {
  try {
    const config = await getConfigByOrgId(accessToken, orgId);
    if (config.moduleConfigurations) {
      let metaInfo: IMetaData | undefined = config.moduleConfigurations[0].modules[0].moduleConfig[0].metaData.find((md: IMetaData) => md.metaId === 'landcareSiteManager');
      if (metaInfo) {
        metaInfo.metaValues = config.siteManagers.map((sm: ISubUser) => (
          { id: sm._id, name: sm.firstName + ' ' + sm.lastName}
        )) as IMetaValue[];
      }
      
      let modules = config.moduleConfigurations[0].modules;

      modules.forEach((module: IModule) => {
        module.pages.forEach((page: IModulePage) => {
          page.sections.forEach((section: IModuleSection) => {
            section.fields.sort((field1: IMetaData, field2: IMetaData) => {
              return (field1?.metaDisplayOrder||0) > (field2?.metaDisplayOrder||0) ? 1 : 0
            })
          })
        })
      });

      let configData: IModuleConfigData = {
        modules,
        error: {}
      }
      dispatch(getModuleConfigDataSuccess(configData));
      
      //dispatch(getModuleConfigSuccess({modules}));
    }
  }
  catch (err: any) {
    dispatch(getModuleConfigDataFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const ModuleConfigDataReducers = moduleConfigDataSlice.reducer;

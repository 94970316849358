import { IUser } from '../../Models/App/AppModels';
import { IUserRole } from '../../Models/Auth/IUser';
import { ISampleRecord } from '../../Models/SampleRecord/ISampleRecord';
import UserApiService from '../../Services/UserApiService';
import { ICreateUser } from '../Reducers/UserSlice';

const userApiService = new UserApiService();

export const getAllUsers = async (accessToken: string, pageNumber: number, pageSize: number) : Promise<any> => {  
  return await userApiService.getAllUsers(accessToken, pageNumber, pageSize);
};

export const getUserById = async (accessToken: string, userId: string) : Promise<any> => {  
  return await userApiService.getUserById(accessToken, userId);
};

export const patchUserById = async (accessToken: string, user: IUser) : Promise<any> => {  
  return await userApiService.patchUserById(accessToken, user);
};

export const patchUserRoleById = async (accessToken: string, user: IUser, userRoles: IUserRole[]) : Promise<any> => {  
  return await userApiService.patchUserRoleById(accessToken, user, userRoles);
};

export const postUser = async (accessToken: string, user: ICreateUser) : Promise<any> => {  
  return await userApiService.postUser(accessToken, user);
};

export const deleteUserById = async (accessToken: string, userId: string) : Promise<any> => {  
  return await userApiService.deleteUserById(accessToken, userId);
};




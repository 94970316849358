import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "react-widgets/styles.css";

import IAuthProps from '../../Containers/Auth/IAuthProps';
import Sidebar from '../Layout/Sidebar/Sidebar';
import '../App/App.scss';

const OrganisationManagement : React.FC<IAuthProps> = (props) => {
  return (
    <Container fluid className="my-3">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Sidebar key="sidebar" highlight='organisation' active={'active'}  >
            Organisation Management
          </Sidebar>
        </Col>
      </Row>
    </Container>
  );
}

export default OrganisationManagement;
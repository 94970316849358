import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { IUserProfile, IUserRole } from '../../Models/Auth/IUser';
import { fetchUserProfile } from '../Api/ModuleConfigApi';
import { AppThunk } from '../RootStore';

export interface IEditingRow {
  rowNo: number;
  isEditing: boolean;
};

export interface IFocusInfo {
  fieldName: string;
  rowNo: number;
};

export enum AuthStatusEnum {
  Undefined,
  Authenticated,
  NotAuthenticated
};

export interface LocalStoreState {
  authStatus: AuthStatusEnum;
  userProfile: IUserProfile;
  isSideMenuExpanded: boolean;
  error: any;
  modulePageInfo: {
    user: IModulePageInfo
  };
  isLoading: boolean;
};

export interface IModulePageInfo {
  pageNumber?: number,
  pageSize?: number,
  recordCount?: number
};

export interface IModulePageInfoPayload {
  moduleName: ModulesEnum,
  modulePageInfo: IModulePageInfo
}

const initialState: LocalStoreState = {
  authStatus: AuthStatusEnum.Undefined,
  userProfile: {} as IUserProfile,
  isSideMenuExpanded: false,
  error: {},
  modulePageInfo: {
    user: {
      pageNumber: 1,
      pageSize: 10,
      recordCount: 0
    }
  },
  isLoading: false
};

export enum ModulesEnum {
  User
};

const LocalStoreSlice = createSlice({
  name: "localStore",
  initialState,
  reducers: {
    setAuthStatus(state: LocalStoreState, {payload}: PayloadAction<AuthStatusEnum>) {
      state.authStatus = payload
    },
    setModulePageInfo(state: LocalStoreState, {payload}: PayloadAction<IModulePageInfoPayload>) {
      switch (payload.moduleName) {
        case ModulesEnum.User: state.modulePageInfo.user = {
          ...state.modulePageInfo.user,
          ...payload.modulePageInfo
        };
        break;
      }
    },
    getUserProfileSuccess(state: LocalStoreState, {payload}: PayloadAction<IUserProfile>) {
      state.userProfile = payload;
    },
    getUserProfileFailed(state: LocalStoreState, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    toggleSideMenu(state: LocalStoreState) {
      state.isSideMenuExpanded = !state.isSideMenuExpanded;
    },
    setSideMenuExpanded(state: LocalStoreState, {payload}: PayloadAction<boolean>) {
      state.isSideMenuExpanded = payload;
    },
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },
  }
});

export const { setAuthStatus, setModulePageInfo, getUserProfileSuccess, getUserProfileFailed, toggleSideMenu, setSideMenuExpanded, startLoading, stopLoading } = LocalStoreSlice.actions;

//KS: Export reducer (Can include other reducers here too)
export const LocalStoreReducers = LocalStoreSlice.reducer;

export const getUserProfile = (accessToken: string) : AppThunk => async dispatch => {
  try {
    const result = await fetchUserProfile(accessToken);
    const index = result?.roles?.findIndex((role: IUserRole) => role.roleName === 'GERXUser');
    if (index === -1) {
      console.log("User not authorized ; should not have acess to the portal");
      //history.push('/unauthorized');
    }
    await dispatch(getUserProfileSuccess(result));
  } catch(err) {
    dispatch(getUserProfileFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

import { IUserProfile } from '../../Models/Auth/IUser';
import ModuleConfigApiService from '../../Services/ModuleConfigApiService';

const moduleConfigApiService = new ModuleConfigApiService();

export const getConfigByOrgId = async (accessToken: string, orgId: string) : Promise<any> => {
  return await moduleConfigApiService.getConfigByOrgId(accessToken, orgId);
};

export const fetchUserProfile = async (accessToken: string) : Promise<IUserProfile> => {  
  return await moduleConfigApiService.getUserProfile(accessToken);
};
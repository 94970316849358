import { createSelector } from 'reselect';
import { IUserOrgState } from '../Reducers/UserOrgSlice';

import { RootState } from '../RootStore';

export const selectUserOrg = (state: RootState) => state.userOrgStore;

export const selectUserOrgs = createSelector(
  [selectUserOrg],
  (state: IUserOrgState) => state.userOrganisations
);

export const selectCurrentUserOrg = createSelector(
  [selectUserOrg],
  (state: IUserOrgState) => state.currentUserOrganisation
);

import axios from 'axios';
import { MessageModeEnum, showMessage } from '../Helpers/Validator/validationHelper';
import { getConfig, getUrlParamWithValue } from './serviceHelper';

class UserRoleApiService {

  baseUrl: string | undefined;
  baseUrlHoldings: string | undefined;
  baseModuleName: string | undefined;
  containerName: string | undefined;
  
  constructor () {
    // https://gerxdev.azure-api.net/
    this.baseUrl = process.env.REACT_APP_API_URI;
    // core
    this.baseModuleName = process.env.REACT_APP_BASE_MODULE_NAME;
  }
 
  showComposedMessage = (methodName: string, url: string, error: any) => {
    const message = `[${methodName}]: ${error.message} - ${url}`;
    showMessage(message, MessageModeEnum.ERROR);
    console.log(message);
  }

  getAllRoles = async (accessToken: string) : Promise<any> => {
    const url = `${this.baseUrl}${this.baseModuleName}/roles`;
    try {
      const config = getConfig(accessToken);
      const response = await axios.get(url, config);
      return response.data;
    } catch(ex: any) {
      this.showComposedMessage('getAllRoles', url, ex);
    }
  }
}

export default UserRoleApiService;
import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Button, FormCheck } from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { cloneDeep } from 'lodash';

import IAuthProps from '../../../Containers/Auth/IAuthProps';
import PageTitle from '../../Layout/PageTitle/PageTitle';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import { ITenancy, IUser, IUserOrganisation } from '../../../Models/App/AppModels';
import { IUserRole } from '../../../Models/Auth/IUser';
import { loaderStyle, waitingText } from '../../../Helpers/constants';
import { createUser, getUser, ICreateUser, patchUser, patchUserRole } from '../../../Store/Reducers/UserSlice';
import { selectUser } from '../../../Store/Selectors/userSelectors';
import { selectRoles } from '../../../Store/Selectors/userRoleSelector';
import { selectTenancies, selectUserTenancy } from '../../../Store/Selectors/tenancySelector';
import { selectLoadingState } from '../../../Store/Selectors/rootSelector';
import { selectCurrentUserOrg, selectUserOrgs } from '../../../Store/Selectors/userOrgSelectors';
import { getUserRoles } from '../../../Store/Reducers/UserRoleSlice';
import { startLoading, stopLoading } from '../../../Store/Reducers/LocalStoreSlice';
import { getTenancies, getUserTenancy } from '../../../Store/Reducers/TenancySlice';
import { getUserOrg, getUserOrgs } from '../../../Store/Reducers/UserOrgSlice';

import "react-widgets/styles.css";

export interface IValidation {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
}

interface IRoleForm {
  createdAt: Date;
  createdBy: Date;
  isDeleted: boolean;
  label: string;
  name: string;
  permissions?: any; 
  updatedAt: Date;
  updatedBy?: any;
  validFrom: Date;
  validUntil: Date;
  isSet?: boolean;
  _id: string;
}

const User : React.FC<IAuthProps> = (props: any) => {

  const [isEditUser, setEditUser] = useState<boolean>(false);
  const [tenancyError, setTenancyError] = useState<boolean>(false);
  const [orgError, setOrgError] = useState<boolean>(false);
  const [tenancy, setTenancy] = useState<string>("");
  const [userOrg, setUserOrg] = useState<string>("");
  
  // localstate to keep track of current user's roles
  const [rolesOnCurrentUser, setRolesOnCurrentUser] = useState<IUserRole[]>();
  const [userRoles, setUserRoles] = useState<IRoleForm[]>();

  const isLoading = useSelector(selectLoadingState);
  const user = useSelector(selectUser);
  const currentUserOrg = useSelector(selectCurrentUserOrg);
  const currentUserTenancy = useSelector(selectUserTenancy);
  
  const userRolesFromApi = useSelector(selectRoles);
  const tenanciesFromApi = useSelector(selectTenancies);
  const userOrgsFromApi = useSelector(selectUserOrgs);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadUser = async () => {
      const accessToken = await props.getAccessToken();
      const userId = window.location.pathname.split('/')[2];

      if (userId) {
        setEditUser(true);
      } else {
        // reset the form state
        onCancel();
        setUserRoles([]);
        setRolesOnCurrentUser([]);
      }
      
      if (accessToken && userId) {
        dispatch(startLoading());
        await dispatch(getUser(accessToken, userId));
        dispatch(stopLoading());
      }
    };
    loadUser();
  }, []);

  useEffect(() => {
    const loadRoles = async () => {
      const accessToken = await props.getAccessToken();

      if (accessToken) {
        dispatch(startLoading());
        await dispatch(getUserRoles(accessToken));
        dispatch(stopLoading());
      }
    };
    loadRoles();
  }, []);

  // for resetting the error message on state change
  useEffect(() => {
    if (tenancy.length) {
      setTenancyError(false);
    }
  }, [tenancy]);

  // for resetting the error message on state change
  useEffect(() => {
    if (userOrg.length) {
      setOrgError(false);
    }
  }, [userOrg]);

  useEffect(() => {
    const loadUserData = async () => {
      if(user.hasOwnProperty('roles')) {
        setValue('contactFirstName', user.firstName);
        setValue('contactLastName', user.lastName);
        setValue('contactEmail', user.email);

        if (user?.roles?.length) {
          setRolesOnCurrentUser(user.roles);
        }
      }
      if (user.organisationId) {
        const accessToken = await props.getAccessToken();
        dispatch(startLoading());
        await dispatch(getUserTenancy(accessToken, user.organisationId));
        await dispatch(getUserOrg(accessToken, user.userOrganisationId));       
        dispatch(stopLoading());
      }
    };
    loadUserData();
  }, [user]);

  useEffect(() => {
    const loadUserOrgsAndTenancies = async () => {
      const accessToken = await props.getAccessToken();

      if (accessToken) {
        await dispatch(getUserOrgs(accessToken));
        await dispatch(getTenancies(accessToken));
      }
    }
    loadUserOrgsAndTenancies();
  }, []);

  useEffect(() => {
    const setCheckBoxes = () => {
      let userRolesWithIsSet : IRoleForm[] = [];
      dispatch(startLoading());
      if (userRolesFromApi?.length && rolesOnCurrentUser?.length) {
        userRolesFromApi.forEach(role => {

          // if the current user has existing role
          const singleUserRole = rolesOnCurrentUser?.filter(userRole => userRole.roleId === role._id)

          if (singleUserRole.length) {
            // set isSet to true
            let userWithIsSet = {
              ...role,
              isSet: true
            }
            userRolesWithIsSet.push(userWithIsSet);
          } else {
            userRolesWithIsSet.push(role);
          }
        });

        
      } else {
        userRolesFromApi.forEach(role => {
          userRolesWithIsSet.push(role);
        });
      }

      setUserRoles(userRolesWithIsSet);
      dispatch(stopLoading());
    }
    setCheckBoxes();
  }, [userRolesFromApi, rolesOnCurrentUser]);

  let shape: any = {
    contactFirstName: yup.string().required("First Name is required").min(2, 'Must be at least 2 chars'),
    contactLastName: yup.string().required("Last Name is required").min(2, 'Must be at least 2 chars'),
    contactEmail: yup.string().required("Email is required").email("Email must be a valid email"),
  };

  const UserSchema = yup.object().shape(shape);

  const { register, handleSubmit, reset, setValue, formState:{ errors } } = useForm<any>({
    defaultValues: {
    },
    mode: "all",
    resolver: yupResolver(UserSchema)
  });

  const updateCheckBox = async (roleId: string, roleName: string, event: any) => {
      
    let isAddingUserRole = event.target.checked;

    let rolesOnUpdate = cloneDeep(rolesOnCurrentUser);

    if (isAddingUserRole) {
      // add the user's role to current user's roles
      let userRoleToAdd : IUserRole = {
        roleId,
        roleName
      }
      rolesOnUpdate?.push(userRoleToAdd);
    } else {
      // if we need to remove
      rolesOnUpdate = rolesOnUpdate?.filter((role : IUserRole) => role.roleId !== roleId);
    }

    setRolesOnCurrentUser(rolesOnUpdate);
  };

  const onSubmit = async (data : any) => {
    const accessToken = await props.getAccessToken();

    if(!(tenancy.length)) {
      setTenancyError(true);
    }

    if(!(userOrg.length)) {
      setOrgError(true);
    }

    if (isEditUser) {
      const patchedUser : IUser = {
        firstName : data.contactFirstName,
        lastName : data.contactLastName,
        _id : user._id,
        userOrganisationId: user.userOrganisationId
      };
      
      dispatch(startLoading());
      await dispatch(patchUser(accessToken, patchedUser))
  
      if (rolesOnCurrentUser) {
        await dispatch(patchUserRole(accessToken, patchedUser, rolesOnCurrentUser));
      }
      dispatch(stopLoading());
    } else {
        if (tenancy && (tenancy.length) && userOrg && (userOrg.length) && rolesOnCurrentUser) {
          let userToCreate : ICreateUser = {
            organisationId: tenancy,
            userOrganisationId: userOrg,
            email: data.contactEmail,
            firstName: data.contactFirstName,
            lastName: data.contactLastName,
            roles: rolesOnCurrentUser,
            isActive: true,
          }
          dispatch(startLoading());
          await dispatch(createUser(accessToken, userToCreate));
          dispatch(stopLoading());
        }
    }
  };

  const onCancel = () => {
    reset();
  };

  return (
    <Container fluid className="my-3">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Sidebar key="sidebar" highlight='users' active={'active'}  >
          <Row>
              <Col>
                <PageTitle title="User Management" />
              </Col>
            </Row>
            <Container fluid>
            <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
              <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
                <Row>
                  <Col className="mb-3">
                    <h5>{isEditUser ? "Edit User" : "Add New User"}</h5>
                  </Col>
                </Row>
                <Row>
                  {
                    // if edit user ; then just show tenancy & user org name
                    isEditUser ? 
                    <>
                    <Col xs={12} sm={6} md={4} lg={4}>
                      <Form.Group>
                        <Form.Label>Tenancy:</Form.Label>
                        <Form.Control disabled={isEditUser}  id="contactTenancy" type="text" value={currentUserTenancy?.name} />
                      </Form.Group>
                    </Col> 
                    <Col xs={12} sm={6} md={4} lg={4}>
                      <Form.Group>
                        <Form.Label>User Organisation:</Form.Label>
                        <Form.Control disabled={isEditUser}  id="contactOrgName" type="text" value={currentUserOrg?.userOrgName} />
                      </Form.Group>
                    </Col> 
                    </> :
                    // else show dropdown selector for selecting user org
                    <Col xs={12} sm={6} md={4} lg={4}>
                      <Form.Group>
                            <Form.Label>Select Tenancy</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={e => {
                                const tenancyId = e.target.value;
                                setTenancy(tenancyId);
                                setTenancyError(false);
                              }}
                            >
                              <option value="">Please select a tenancy</option>
                              {
                                tenanciesFromApi && tenanciesFromApi.length && tenanciesFromApi.map((tenancy : ITenancy)=> {
                                  return (<option key={tenancy._id} value={tenancy._id}>{tenancy.name}</option>)
                                })
                              }
                            </Form.Control>
                        <div style={tenancyError ? { display: 'block' } : { display: 'none' }} className="invalid-feedback">Tenancy is required</div> 
                      </Form.Group>

                      <Form.Group>
                            <Form.Label>Select User Organisation</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={e => {
                                const orgId = e.target.value;
                                setUserOrg(orgId);
                                setOrgError(false);
                              }}
                            >
                              <option value="">Please select User Organisation</option>
                              {
                                userOrgsFromApi && userOrgsFromApi.length && userOrgsFromApi.map((userOrg : IUserOrganisation)=> {
                                  return (<option key={userOrg._id} value={userOrg._id}>{userOrg.userOrgName}</option>)
                                })
                              }
                            </Form.Control>
                        <div style={orgError ? { display: 'block' } : { display: 'none' }} className="invalid-feedback">Organisation is required</div> 
                      </Form.Group>
                    </Col>
                    }
                </Row>
                <Row>                  
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control isInvalid={!!errors.contactFirstName} id="contactFirstName" type="text" {...register('contactFirstName')} placeholder="First Name" />
                        <Form.Control.Feedback type="invalid">{errors.contactFirstName?.message} </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control isInvalid={!!errors.contactLastName} id="contactLastName" type="text" {...register('contactLastName')} placeholder="Last Name" />
                        <Form.Control.Feedback type="invalid">{errors.contactLastName?.message} </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control disabled={isEditUser} isInvalid={!!errors.contactEmail} id="contactEmail" type="text" {...register('contactEmail')}  placeholder="Email" />
                        <Form.Control.Feedback type="invalid">{errors.contactEmail?.message} </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                   
                    <Col xs={12} sm={12} md={12} lg={12} className="text-right">
                        <Button className="btn btn-warning" onClick={() => props.history.push(`/users/`)}>Cancel</Button>&nbsp;
                        <Button disabled={isEditUser} onClick={onCancel}>Clear</Button>&nbsp;
                        <Button type="submit" variant="success">Save</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>User Roles:</Form.Label>
                      </Form.Group>
                    </Col>
                  {
                    userRoles?.map((userRole: IRoleForm, idx: number) => {
                      return (
                        <Col lg={12} key={userRole._id} >
                          <Form.Group>
                            <FormCheck>
                              <FormCheck.Input onClick={(event: any) => updateCheckBox(userRole._id, userRole.name, event)} defaultChecked={userRole?.isSet} type="checkbox"  /> 
                              <FormCheck.Label>{userRole.name}</FormCheck.Label>
                            </FormCheck>                              
                          </Form.Group>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Form>
            
            </LoadingOverlay>
            </Container>
          </Sidebar>
        </Col>
      </Row>
    </Container>
  );
}

export default User;
import React , {useState, useEffect} from 'react';
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../../Layout/Sidebar/Sidebar';
import PageTitle from '../../Layout/PageTitle/PageTitle';
import { loaderStyle, waitingText } from '../../../Helpers/constants';
import PaginationTable from '../../../Helpers/UI/PaginationTable/PaginationTable';
import { IUser } from '../../../Models/App/AppModels';
import { deleteUser, getUsers } from '../../../Store/Reducers/UserSlice';
import { selectLoadingState, selectUserManagementPageInfo } from '../../../Store/Selectors/rootSelector';
import { selectUsers, userSelector } from '../../../Store/Selectors/userSelectors';
import { IModulePageInfoPayload, ModulesEnum, setModulePageInfo, startLoading, stopLoading } from '../../../Store/Reducers/LocalStoreSlice';

import "react-widgets/styles.css";
import '../../App/App.scss';
import './UserManagement.scss';


const UserManagement : React.FC<any> = (props) => {

  const dispatch = useDispatch();
  const { pageNumber, pageSize, recordCount } = useSelector(selectUserManagementPageInfo);
  const isLoading = useSelector(selectLoadingState);
  const users = useSelector(selectUsers);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<string>("");

  useEffect(() => {
    const loadUsers = async () => {
      const modulePageInfo: IModulePageInfoPayload = {
        moduleName: ModulesEnum.User,
        modulePageInfo: { pageNumber: pageNumber }
      };
      const accessToken = await props.getAccessToken();
      if (accessToken && pageSize && pageNumber) {
        dispatch(startLoading());
        await dispatch(setModulePageInfo(modulePageInfo));
        await dispatch(getUsers(accessToken, pageNumber, pageSize));
        dispatch(stopLoading());
      }
    };
    loadUsers();
  }, [pageNumber, pageSize]);

  const siteManagerFormatter = (cell: string, row: any, rowIndex: number, formatExtraData: any) => {
    
    const isSiteManager = row && row?.roles?.length && row?.roles.some((role: any) => role.roleName === 'SiteManager');

    if (isSiteManager) {
      return <p>Yes</p>;
    } else {
      return <p></p>;
    }
  }

  const columns = [
    {
      dataField: "firstName",
      text: "First Name",
      headerStyle: () => ({ width: "30%" }),
      editable: () => {
        return false;
      },
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      headerStyle: () => ({ width: "30%" }),
      editable: () => {
        return false;
      },
      sort: true,
    },
    {
      dataField: "roles",
      text: "Site Manager",
      headerStyle: () => ({ width: "20%" }),
      formatter: siteManagerFormatter,
      editable: () => {
        return false;
      },
      sort: true,
    },
    {
        dataField: "_id",
        text: "Edit",
        headerStyle: () => ({ width: "10%" }),
        isDummyField: true,
        editable: () => {
          return false;
        },
        formatter: (cell: any, row: any, rowIndex: number) => {
          return (
            <div>
                <i
                  className="fa fa-pen gerx-hand-pointer"
                  title={"Click to Edit User"}
                  onClick={() =>
                    {
                      userRowOnClick(row._id)
                    }
                  }
                />
            </div>
          );
        },
      },
      {
        dataField: "_id",
        text: "Delete",
        headerStyle: () => ({ width: "10%" }),
        isDummyField: true,
        editable: () => {
          return false;
        },
        formatter: (cell: any, row: any, rowIndex: number) => {
          return (
            <div>
                <i
                  className="fa fa-trash gerx-hand-pointer"
                  title={"Click to Delete User"}
                  onClick={() =>
                    {
                      userRowOnClickDelete(row._id)
                    }
                  }
                />
            </div>
          );
        },
      }
  ];

  const userRowOnClick = (id: string) => {
    props.history.push(`/user/${id}`);
  }

  const userRowOnClickDelete = (id: string) => {
    showModalHandler(id);
  }

  const showModalHandler = (id: string) => {
    setShowModal(true);
    setUserToDelete(id);
  }

  // this is used for the delete modal
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleDeleteUser = async () => {
    const accessToken = await props.getAccessToken();
    console.log("jm: delete user ", userToDelete);
    await dispatch(deleteUser(accessToken, userToDelete));
    setShowModal(false);
  }

  const changePageNo = (pageNo: number) => {
    const modulePageInfo: IModulePageInfoPayload = {
      moduleName: ModulesEnum.User,
      modulePageInfo: { pageNumber: pageNo }
    };
    console.log('Page No ===', modulePageInfo);
    dispatch(setModulePageInfo(modulePageInfo));
  };

  const changePageSize = (pageSize: number) => {
    const modulePageInfo: IModulePageInfoPayload = {
      moduleName: ModulesEnum.User,
      modulePageInfo: { pageNumber: 1, pageSize }
    };
    console.log('modulePageInfo ===', modulePageInfo);
    dispatch(setModulePageInfo(modulePageInfo));
  };
  

  return (
    <Container fluid className="userlist my-3">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Sidebar key="sidebar" highlight='users' active={'active'}  >
          <Row>
            <Col>
              <PageTitle title="User Management" />
            </Col>
          </Row>
            <Container fluid>
            <Modal size="lg" show={showModal} onHide={handleCloseModal}> 
              <Modal.Header closeButton>
                <Modal.Title>Delete User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Choosing delete will delete this user.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleDeleteUser}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

              <Button as={Link} to={"/user/"} className="btn btn-primary">Create New User</Button>
              <br></br><br></br><br></br><br></br>
              <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
                <PaginationTable  
                  keyField='_id'
                  data={ users }
                  columns={ columns }
                  classes="table table-bordered table-striped table-sm mt-0"
                  pageNumber={ pageNumber }
                  pageSize={ pageSize }
                  recordCount={ recordCount }
                  onPageChanged={ changePageNo }
                  onPageSizeChanged={ changePageSize }
                />
              </LoadingOverlay>
            </Container>
          </Sidebar>
        </Col>
      </Row>
    </Container>
  );
}

export default UserManagement;
import { createSelector } from 'reselect';

import { RootState } from '../RootStore';
import { IUserRoleState } from '../Reducers/UserRoleSlice';
import { IRole } from '../../Models/App/AppModels';

export const userRolesSelector = (state: RootState) => state.userRolesStore;

export const selectRoles = createSelector(
    [userRolesSelector],
    (state: IUserRoleState) => state.roles as IRole[]
);
import {configureStore, getDefaultMiddleware, combineReducers, Action}  from '@reduxjs/toolkit';
import {ThunkAction} from 'redux-thunk';
import { LocalStoreReducers } from './Reducers/LocalStoreSlice';
import { ModuleConfigDataReducers } from './Reducers/ModuleConfigSlice';
import { TenancyReducers } from './Reducers/TenancySlice';

import { userOrgReducers } from './Reducers/UserOrgSlice';
import { UserRoleReducers } from './Reducers/UserRoleSlice';
import { UserReducers } from './Reducers/UserSlice';

const RootStore = configureStore({
  reducer: combineReducers({
    localStore: LocalStoreReducers,
    moduleConfigStore: ModuleConfigDataReducers,
    userStore: UserReducers,
    tenancyStore: TenancyReducers,
    userRolesStore: UserRoleReducers,
    userOrgStore: userOrgReducers
  }),
  middleware: getDefaultMiddleware(),
  devTools: true
});

export default RootStore;

export type RootState = ReturnType<typeof RootStore.getState>;
export type AppDispatch = typeof RootStore.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
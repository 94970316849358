import { createSelector } from 'reselect';

import { RootState } from '../RootStore';
import {  ITenancy } from '../../Models/App/AppModels';
import { ITenancyState } from '../Reducers/TenancySlice';

export const tenancySelector = (state: RootState) => state.tenancyStore;

export const selectTenancies = createSelector(
    [tenancySelector],
    (state: ITenancyState) => state.tenancies as ITenancy[]
);

export const selectUserTenancy = createSelector(
    [tenancySelector],
    (state: ITenancyState) => state.currentTenancy as ITenancy
);


import axios from 'axios';
import { MessageModeEnum, showMessage } from '../Helpers/Validator/validationHelper';
import { IUser } from '../Models/App/AppModels';
import { IUserRole } from '../Models/Auth/IUser';
import { ISampleRecord } from '../Models/SampleRecord/ISampleRecord';
import { ICreateUser } from '../Store/Reducers/UserSlice';
import { getConfig, getUrlParamWithValue } from './serviceHelper';

class UserApiService {

  baseUrl: string | undefined;
  baseUrlHoldings: string | undefined;
  baseModuleName: string | undefined;
  containerName: string | undefined;
  

  constructor () {
    this.baseUrl = process.env.REACT_APP_ADMINPANEL_API_BASEURI;
    this.baseModuleName = process.env.REACT_APP_BASE_MODULE_NAME;
  }
 
  showComposedMessage = (methodName: string, url: string, error: any) => {
    const message = `[${methodName}]: ${error.message} - ${url}`;
    showMessage(message, MessageModeEnum.ERROR);
    console.log(message);
  }

  getAllUsers = async (accessToken: string, pageNumber: number, pageSize: number) : Promise<any> => {
    
    const url = `${this.baseUrl}${this.baseModuleName}/Users`
                  + getUrlParamWithValue('pagenum', pageNumber, true)
                  + getUrlParamWithValue('pagesize', pageSize);
    try {
      const config = getConfig(accessToken);
      const response = await axios.get(url, config);
      return response.data;
    } catch(ex: any) {
      this.showComposedMessage('getAllUsers', url, ex);
    }
  }

  getUserById = async (accessToken: string, userId: string) : Promise<any> => {
    
    const url = `${this.baseUrl}${this.baseModuleName}/Users`
                  + getUrlParamWithValue('id', userId, true);
    try {
      const config = getConfig(accessToken);
      const response = await axios.get(url, config);
      return response.data;
    } catch(ex: any) {
      this.showComposedMessage('getUserById', url, ex);
    }
  }

  patchUserById = async (accessToken: string, user: IUser) : Promise<any> => {
    
    const url = `${this.baseUrl}${this.baseModuleName}/Users`
                    + getUrlParamWithValue('id', user._id, true);
    try {
      const config = getConfig(accessToken);
      const response = await axios.patch(url, user, config);
      return response.data;
    } catch(ex: any) {
      this.showComposedMessage('patchUserById', url, ex);
    }
  }

  patchUserRoleById = async (accessToken: string, user: IUser, userRoles: IUserRole[]) : Promise<any> => {

    const url = `${this.baseUrl}${this.baseModuleName}/userroles`
                  + getUrlParamWithValue('id', user._id, true);
    
    try {
      const config = getConfig(accessToken);
      const response = await axios.put(url, userRoles, config);
      return response.data;
    } catch(ex: any) {
      this.showComposedMessage('patchUserById', url, ex);
    }
  }

  postUser = async (accessToken: string, user: ICreateUser) : Promise<any> => {

    const url = `${this.baseUrl}${this.baseModuleName}/Users`;

    try {
      const config = getConfig(accessToken);
      const response = await axios.post(url, user, config);
      return response.data;
    } catch(ex: any) {
      this.showComposedMessage('patchUserById', url, ex);
    }
  }

  deleteUserById = async (accessToken: string, userId: string) : Promise<any> => {

    const url = `${this.baseUrl}${this.baseModuleName}/users`
                  + getUrlParamWithValue('id', userId, true);
    try {
      const config = getConfig(accessToken);
      const response = await axios.delete(url, config);
      return response.data;
    } catch(ex: any) {
      this.showComposedMessage('deleteUserById', url, ex);
    }
  }
}

export default UserApiService;
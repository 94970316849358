import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../RootStore';
import { RootState } from "../RootStore";
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { ITenancy } from '../../Models/App/AppModels';
import { getAllTenancies, getTenancy } from '../Api/TenancyApi';

export interface ITenancyState {
  tenancies: ITenancy[],
  currentTenancy: ITenancy,
  error: any,
}

const initialState : ITenancyState = {
  tenancies: [] as ITenancy[],
  currentTenancy: {} as ITenancy,
  error: null,
};

const TenancySlice = createSlice({
  name: "Tenancy",
  initialState: initialState,
  reducers: {
    getTenancySuccess(state, {payload}: PayloadAction<any>) {
      state.tenancies = payload;
      state.error = null;
    },
    getUserTenancySuccess(state, {payload}: PayloadAction<any>) {
      state.currentTenancy = payload;
      state.error = null;
    },
    getTenancyFailed(state, {payload}: PayloadAction<ITenancy[]>) {
      state.tenancies = [] as ITenancy[];
      state.error = payload;
    },
    getUserTenancyFailed(state, {payload}: PayloadAction<ITenancy>) {
      state.currentTenancy = {} as ITenancy;
      state.error = payload;
    },
  }
});

//KS: Export reducer (Can include other reducers here too)
export const TenancyReducers = TenancySlice.reducer;

// //KS: Export action creators
export const {
  getTenancySuccess, 
  getTenancyFailed,
  getUserTenancySuccess,
  getUserTenancyFailed
} = TenancySlice.actions;

/***** API Calls */
export const getTenancies = (accessToken: string) : AppThunk => async dispatch => {
  try {
    const result = await getAllTenancies(accessToken);
    dispatch(getTenancySuccess(result));
  } catch(err) {
    dispatch(getTenancyFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const getUserTenancy = (accessToken: string, tenancyId: string) : AppThunk => async dispatch => {
  try {
    const result = await getTenancy(accessToken, tenancyId);
    dispatch(getUserTenancySuccess(result));
  } catch(err) {
    dispatch(getTenancyFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

/* Export reducer store: */
export const role = (state: RootState) => state.tenancyStore as any;